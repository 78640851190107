<template>
  <div id="app">
    <div v-html="statistics"></div>
    <!-- 基础信息部分 -->
    <div class="basisInfoView">
      <div class="ocrIdentifyTop">
        <div class="title">车况描述</div>
        <div class="text">对评估结果很重要，请认真选择</div>
      </div>

      <div class="basisInfoViewLi" @click="appearanceClick" v-if="!valuationInfo.isTrailer">
        <img
          class="icon_image"
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/exterior_interior_20210804.png"
          alt
        />
        <div class="basisInfoViewLiText">外观及内饰</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          readonly
          placeholder="点击进行描述选择"
          v-model.trim="cabScoreValue"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <div class="basisInfoViewLi" @click="appearanceClick" v-if="valuationInfo.isTrailer">
        <img
          class="icon_image"
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/exterior_20230114.png"
          alt
        />
        <div class="basisInfoViewLiText">外观</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          readonly
          placeholder="点击进行描述选择"
          v-model.trim="cabScoreValue"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <!-- 外观 -->
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="appearanceHidden">
        <van-picker
          title="外观及内饰"
          show-toolbar
          :columns="appearanceColumns"
          @confirm="appearanceConfirm"
          @cancel="appearanceHidden = false"
        />
      </van-popup>
      <div class="basisInfoViewLi" @click="engineClick" v-if="!valuationInfo.isTrailer">
        <img
          class="icon_image"
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/engine_20220826.png"
          alt
        />
        <div class="basisInfoViewLiText">动力系统</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          readonly
          v-model.trim="engineScoreValue"
          placeholder="点击进行描述选择"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="engineHidden">
        <van-picker
          title="发动机"
          show-toolbar
          :columns="engineColumns"
          @confirm="engineConfirm"
          @cancel="engineHidden = false"
        />
      </van-popup>
      <div class="basisInfoViewLi" @click="chassisClick">
        <img
          class="icon_image"
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/chassis_20210804.png"
          alt
        />
        <div class="basisInfoViewLiText">底盘</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          readonly
          v-model.trim="chassisScoreValue"
          placeholder="点击进行描述选择"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="chassisHidden">
        <van-picker
          title="底盘"
          show-toolbar
          :columns="chassisColumns"
          @confirm="chassisConfirm"
          @cancel="chassisHidden = false"
        />
      </van-popup>
      <div class="basisInfoViewLi" @click="skeletonClick">
        <img
         class="icon_image"
          src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/skeleton_20210804.png"
          alt
        />
        <div class="basisInfoViewLiText">骨架</div>
        <input
          style="border-bottom: 1px solid #d8d8d8"
          type="text"
          v-model.trim="skeletonScoreValue"
          readonly
          placeholder="点击进行描述选择"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="skeletonHidden">
        <van-picker
          title="骨架"
          show-toolbar
          :columns="skeletonColumns"
          @confirm="skeletonConfirm"
          @cancel="skeletonHidden = false"
        />
      </van-popup>
    </div>
    <div class="specialView">
      <div class="ocrIdentifyTop" style="padding: 0 10px; box-sizing: border-box">
        <div class="title">特殊减值项</div>
      </div>

      <div class="specialViewLi">
        <div class="text">交易状态</div>
        <van-radio-group
          v-model="specialImpair.transfer"
          @change="radioChange"
          direction="horizontal"
        >
          <van-radio name="1">可过户</van-radio>
          <van-radio name="0">不可过户</van-radio>
        </van-radio-group>
      </div>
      <div class="carInfoViewLi" v-show="specialImpair.transfer == '0'">
        <div class="carInfoViewLiText">不可过户原因</div>
        <input
          type="text"
          @click="canNotClick"
          readonly
          placeholder="请选择不可过户原因"
          v-model.trim="specialImpair.unTransferReason"
        />
        <van-icon style="margin-left: 10px" name="arrow" size="14px" />
      </div>
      <van-popup position="bottom" :style="{ height: '38%' }" round v-model="canNotHidden">
        <van-picker
          title="不可过户原因"
          show-toolbar
          :columns="canNotColumns"
          @confirm="canNotConfirm"
          @cancel="canNotHidden = false"
        />
      </van-popup>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText" :class="[disabled ? 'colorClass' : '']">过户交易税(元)</div>
        <input
          type="text"
          :class="[disabled ? 'colorClass' : '']"
          v-model.trim="specialImpair.tax"
          :oninput="
            (specialImpair.tax = specialImpair.tax.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))
          "
          :readOnly="disabled"
          placeholder="0.00"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText" :class="[disabled ? 'colorClass' : '']">过户手续费(元)</div>
        <input
          type="text"
          v-model.trim="specialImpair.serviceCharge"
          placeholder="0.00"
          :oninput="
            (specialImpair.serviceCharge = specialImpair.serviceCharge.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))
          "
          :class="[disabled ? 'colorClass' : '']"
          :readOnly="disabled"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">停车费(元)</div>
        <input
          type="text"
          v-model.trim="specialImpair.parkingFee"
          placeholder="0.00"
          :oninput="
            (specialImpair.parkingFee = specialImpair.parkingFee.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))
          "
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">代驾费(元)</div>
        <input
          type="text"
          v-model.trim="specialImpair.drivingFee"
          :oninput="
            (specialImpair.drivingFee = specialImpair.drivingFee.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))
          "
          placeholder="0.00"
        />
      </div>
      <div class="carInfoViewLi">
        <div class="carInfoViewLiText">违章罚款(元)</div>
        <input
          type="text"
          v-model.trim="specialImpair.violation"
          placeholder="0.00"
          :oninput="
            (specialImpair.violation = specialImpair.violation.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))
          "
        />
      </div>
    </div>
    <div class="buttonView">
      <button class="cancelBut" @click="cancelButClick">上一步</button>
      <button @click="confirmButClick" class="confirmBut">快速估值</button>
    </div>
    <van-popup position="bottom" round v-model="reginHidden">
      <div class="othTitle">更多配置</div>
      <ul class="o_ul">
        <li v-for="(item,index) in otherContent" :key="index">
          <div class="o_div" @click="windowShowInfo(item)">
            <div>{{item.name}}</div>
            <div v-if="item.value">{{item.value}}</div>
            <div v-if="!item.value" style="color: #999999;">请选择{{item.name}}</div>
            <van-icon name="arrow" size="18px" />
          </div>
          <div class="o_dis" v-if="item.value">
            <div class="oDiv">
              <div>图片</div>
              <div>请根据业务要求上传图片</div>
            </div>
            <van-uploader :after-read="(file) => additionalImage(file, item)" :name="item.name">
              <div class="image">
                <!-- 顶部加号 -->
                <img v-if="!item.img" src="@/assets/adds.png" style="width: 24px; height: 24px" alt />
                <!-- 删除图片 -->
                <img
                  v-if="item.img"
                  class="remoteImage"
                  src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/error_symbol_20210527.png"
                  alt
                  @click="imageDelete(item.img)"
                />
                <!-- 用户上传图片 -->
                <img
                  :key="item.name"
                  v-if="item.img"
                  style="width: 100%; height: 100%"
                  :src="item.img"
                  alt
                />
              </div>
            </van-uploader>
            <div class="texts">{{ item.imageName }}</div>
          </div>
        </li>
      </ul>
      <div class="confrims" @click="onConfirmOther">确定</div>
    </van-popup>
    <van-popup position="bottom" :style="{ height: '50%' }" round v-model="selectValue">
      <van-picker
        :title="otherName"
        show-toolbar
        :columns="otherList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>
<script type="text/javascript" src='https://c.cnzz.com/core.php?web_id=1281102051&t=z' charset="utf-8">
</script>
<script>
import { Toast, Dialog } from "vant";
import {
  getSinglePayStatus,
  createAppOrder,
  getVehicleSeries, //特殊车型
  getOtherOptions, //特殊车型配置
  upPicture,
} from "@/utils/http.js"; //路径注意

export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      selectValue: false,
      otherName: "",
      otherList: [],
      reginHidden: false,
      otherContent: [],
      seriesList: [],
      statistics: "",
      //       engineBrand: ""
      // engineModel: ""
      // 上个页面的数据
      valuationInfo: { isTrailer: "" },
      //外观
      appearanceHidden: false, //外观显示隐藏
      cabScoreValue: "干净完整", //v-model 外观
      appearanceColumns: ["干净完整", "略脏、有维修", "脏乱、有大修"],
      //   发动机
      engineHidden: false, //发动机显示隐藏
      engineScoreValue: "无维修", //v-model 发动机
      engineColumns: ["无维修", "1-3次有维修", "4次及以上有维修"],
      //   地盘
      chassisHidden: false, //底盘显示隐藏
      chassisScoreValue: "部件无老化", //v-model 地盘
      chassisColumns: ["部件无老化", "1-3个部件老化", "4个及以上部件老化"],
      //   骨架
      skeletonHidden: false, //骨架显示隐藏
      skeletonScoreValue: "部件无缺陷", //v-model 骨架
      skeletonColumns: ["部件无缺陷", "1-3个部件有缺陷", "4个及以上部件有缺陷"],
      //   只读
      disabled: false,
      canNotHidden: false, //不可以过户原因 显示隐藏
      canNotColumns: [
        "挂靠公司限制",
        "权益抵押",
        "司法机关扣押",
        "法律限制交易",
        "其他不明因素",
      ],
      specialImpair: {
        drivingFee: "", //代驾费
        parkingFee: "", //停车费
        serviceCharge: "", //过户手续
        tax: "", //过户交易
        transfer: "1", //1是可以交易, 0不可以交易
        unTransferReason: "", //不可过户原由
        violation: "", //违章惩罚
      },
      obj: {
        cabScore: "0", //外观
        engineScore: "0", //发动机
        skeletonScore: "0", //骨架
        chassisScore: "0", //  地盘
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    onCancel() {
      this.selectValue = false;
    },
    onConfirm(e) {
      console.log(e);
      this.otherContent.forEach((item) => {
        if (item.name == this.otherName) {
          item.value = e;
        }
      });
      console.log(this.otherContent);
      let params = this.valuationInfo.charts;
      if (params) {
        for (let key in params) {
          this.otherContent.forEach((item) => {
            if (key == "urlPic" + item.imageType) {
              item.img = params[key];
            }
          });
        }
      }
      console.log(this.valuationInfo.charts);
      this.selectValue = false;
    },
    //   点击外观
    appearanceClick() {
      this.appearanceHidden = true;
    },
    // 外观点击确认
    appearanceConfirm(event, index) {
      this.cabScoreValue = event;
      this.obj.cabScore = index;
      this.appearanceHidden = false; //隐藏
    },
    // 点击发动机
    engineClick() {
      this.engineHidden = true;
    },
    // 发动机确认按钮
    engineConfirm(event, index) {
      this.engineScoreValue = event;
      this.obj.engineScore = index;
      this.engineHidden = false;
    },
    // 点击底盘
    chassisClick() {
      this.chassisHidden = true;
    },
    // 底盘确认按钮
    chassisConfirm(event, index) {
      // console.log(event, index);
      this.chassisScoreValue = event;
      this.obj.chassisScore = index;
      this.chassisHidden = false;
    },
    // 点击骨架
    skeletonClick() {
      this.skeletonHidden = true;
    },
    // 骨架确认按钮
    skeletonConfirm(event, index) {
      // console.log(event, index);
      this.obj.skeletonScore = index;
      this.skeletonScoreValue = event;
      this.skeletonHidden = false;
    },
    // 复选框
    radioChange(event) {
      this.specialImpair.tax = "";
      this.specialImpair.serviceCharge = "";
      this.specialImpair.unTransferReason = "";
      // console.log(event);
      if (event == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 点击不可以过户
    canNotClick() {
      this.canNotHidden = true;
    },
    // 选择原因确认按钮
    canNotConfirm(event) {
      // console.log(event);
      this.specialImpair.unTransferReason = event;
      this.canNotHidden = false;
    },
    //  点击返回
    cancelButClick() {
      this.$router.go(-1);
    },
    // 跳转页面
    async confirmButClick() {
      let specialImpair = this.specialImpair;
      let obj = this.obj;
      console.log(obj);
      if (
        obj.chassisScore === "" ||
        obj.cabScore === "" ||
        obj.engineScore === "" ||
        obj.skeletonScore === ""
      ) {
        Toast("请填写必要的表单项");
        return;
      }
      if (specialImpair.transfer == "1") {
        specialImpair.unTransferReason = "交易状态";
        for (var num in specialImpair) {
          if (
            num == "tax" ||
            num == "serviceCharge" ||
            num == "parkingFee" ||
            num == "drivingFee" ||
            num == "violation"
          ) {
            if (specialImpair[num] == "") {
              specialImpair[num] = "0.00";
            }
            if (specialImpair[num].indexOf(".") > -1) {
              var n = specialImpair[num].substring(
                specialImpair[num].indexOf(".")
              );
              if (n.length == 3) {
              } else {
                specialImpair[num] = specialImpair[num] + "0";
              }
            } else {
              specialImpair[num] = specialImpair[num] + ".00";
            }
            // console.log(specialImpair[num]);
          }
        }
      } else {
        if (specialImpair.unTransferReason == "") {
          Toast("请填写必要的表单项");
          return;
        }
        for (var num in specialImpair) {
          if (
            num == "parkingFee" ||
            num == "drivingFee" ||
            num == "violation"
          ) {
            if (specialImpair[num] == "") {
              specialImpair[num] = "0.00";
            }
            // console.log(specialImpair[num]);

            if (specialImpair[num].indexOf(".") > -1) {
              var n = specialImpair[num].substring(
                specialImpair[num].indexOf(".")
              );
              if (n.length == 3) {
              } else {
                specialImpair[num] = specialImpair[num] + "0";
              }
            } else {
              specialImpair[num] = specialImpair[num] + ".00";
            }
          }
        }
      }
      //-----------------------
      this.specialImpair = specialImpair;
      let params = {
        brand: this.valuationInfo.brand,
        engineModel: this.valuationInfo.engineModel,
        vehicleModel: this.valuationInfo.vehicleModel,
        appKey: this.valuationInfo.appKey,
        sign: this.valuationInfo.sign,
        randomStr: this.valuationInfo.randomStr,
        expireTime: this.valuationInfo.expireTime,
        token: this.valuationInfo.token || "",
      };
      const { data: res } = await getVehicleSeries(params);
      console.log(res);
      if (res.data.result.content.view) {
        this.reginHidden = true;
        this.seriesList = res.data.result.content.series;
        setTimeout(async () => {
          const { data: re } = await getOtherOptions(params);
          re.data.result.content.forEach((item) => {
            item.value = "";
            item.img = "";
          });
          this.otherContent = re.data.result.content;
          console.log(this.otherContent);
        }, 500);
      } else {
        this.rightClick();
      }
    },
    windowShowInfo(data) {
      console.log(data);
      this.selectValue = true;
      let list = [];
      data.options.forEach((item) => {
        list.push(item.label);
      });
      this.otherName = data.name;
      this.otherList = list;
    },
    async additionalImage(file, detail) {
      // console.log(file.file);
      const param = new FormData();
      param.append("image", file.file);
      param.append("appKey", this.valuationInfo.appKey);
      param.append("sign", this.valuationInfo.sign);
      param.append("randomStr", this.valuationInfo.randomStr);
      param.append("expireTime", this.valuationInfo.expireTime);
      param.append("token", this.valuationInfo.token || "");
      const { data: res } = await upPicture(param);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      this.otherContent.forEach((item) => {
        if (item == detail) {
          item.img = res.data.imgUrl;
        }
      });
      let params = this.valuationInfo.charts;
      if (params) {
        for (let key in params) {
          this.otherContent.forEach((item) => {
            if (key == "urlPic" + item.imageType) {
              params[key] = item.img;
            } else {
              params["urlPic" + item.imageType] = item.img;
            }
          });
        }
      } else {
        let obj = {};
        this.otherContent.forEach((item) => {
          if (item.img) {
            obj["urlPic" + item.imageType] = item.img;
          }
        });
        this.valuationInfo.charts = obj;
      }
    },
    // 删除图片
    imageDelete(data) {
      this.otherContent.forEach((item) => {
        if (item.img == data) {
          item.img = "";
        }
      });
      let params = this.valuationInfo.charts;
      for (let key in params) {
        this.otherContent.forEach((item) => {
          if (params[key] == data) {
            params[key] = "";
          }
        });
        if (params[key]) {
          this.valuationInfo.charts = params;
        } else {
          this.valuationInfo.charts = "";
        }
      }
      // console.log(this.valuationInfo.charts);
      // console.log(this.otherContent);
    },
    tips(data) {
      if (data) {
        if (data == 1000) {
          Dialog.alert({
            title: "提示信息",
            message: "验证失败,请返回上级页面重新验证",
          }).then(() => {});
          return false;
        }
        if (data == 1001) {
          Dialog.alert({
            title: "提示信息",
            message: "appKey错误",
          }).then(() => {});
          return false;
        }
        if (data == 1002) {
          Dialog.alert({
            title: "提示信息",
            message: "暂无权限",
          }).then(() => {});
          return false;
        }
        if (data == 1003) {
          Dialog.alert({
            title: "提示信息",
            message: "余额不足",
          }).then(() => {});
          return false;
        }
        if (data == 1004) {
          Dialog.alert({
            title: "提示信息",
            message: "签名已过期",
          }).then(() => {});
          return false;
        }
      }
      return true;
    },
    onConfirmOther() {
      let params = false;
      let oParams = {};
      this.otherContent.forEach((item) => {
        if (!item.value) {
          params = true;
        }
        oParams[item.parameterName] = item.value;
        if (item.value == "有") {
          oParams[item.parameterName] = 1;
        }
        if (item.value == "无") {
          oParams[item.parameterName] = 0;
        }
      });
      if (params) {
        Toast("配置项未选择");
        return;
      }
      Object.assign(this.valuationInfo, oParams);
      this.reginHidden = false;
      this.rightClick();
    },
    async rightClick() {
      if (!this.valuationInfo.isView) {
        if (this.valuationInfo.engine.length != 0) {
          this.valuationInfo.engine.forEach((item) => {
            this.valuationInfo.engineBrand = item.engineName;
            this.valuationInfo.engineModel = item.engineModels[0].engineName;
          });
        }
      }

      Object.assign(this.valuationInfo, this.obj);
      this.valuationInfo.specialImpair = this.specialImpair;
      localStorage.setItem("valuationv2", JSON.stringify(this.valuationInfo));
      var valuationInfo = {
        appKey: this.valuationInfo.appKey,
        sign: this.valuationInfo.sign,
        randomStr: this.valuationInfo.randomStr,
        expireTime: this.valuationInfo.expireTime,
        token: this.valuationInfo.token || "",
        functionDesc: "QuickValuation",
        content: JSON.stringify(this.valuationInfo),
      };
      let { data: isPay } = await getSinglePayStatus({
        appKey: this.valuationInfo.appKey,
        sign: this.valuationInfo.sign,
        randomStr: this.valuationInfo.randomStr,
        expireTime: this.valuationInfo.expireTime,
        token: this.valuationInfo.token || "",
      });
      console.log(isPay);
      if (!isPay.data.isTrailer) {
        this.$router.push("/quickValuation/v2/details?key=1");
        return;
      }
      let res = await createAppOrder(valuationInfo);
      // console.log(res);
      let keys = this.tips(res.code);
      if (!keys) {
        return;
      }
      if (res.data.resultStates !== 0) {
        Dialog.alert({
          title: "提示信息",
          message: "订单编号获取失败",
        }).then(() => {});
        return;
      }
      console.log(this.valuationInfo);
      this.$router.push("/quickValuation/v2/details?key=1");
      // window.open(`http://wxsp-used.sih.cq.cn/m/quickEstimate/payPage.html?orderNo=${res.data.content}&token=${valuationInfo.token}`)
      // location.replace(
      //   `https://used.trucklc.com/m/quickEstimate/payPage.html?orderNo=${res.data.content}&token=${valuationInfo.token}`
      // );
      return;
    },
  },
  mounted() {
    let str = unescape(
      "%3Cspan id='cnzz_stat_icon_1281102051'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1281102051' type='text/javascript'%3E%3C/script%3E"
    );
    // this.statistics = str;
    var valuationInfo = localStorage.getItem("valuationInfo");
    this.valuationInfo = JSON.parse(valuationInfo);
    // this.confirmButClick();
    // console.log(this.valuationInfo.isTrailer);
  },
  activated() {
    var valuationInfo = localStorage.getItem("valuationInfo");
    this.valuationInfo = JSON.parse(valuationInfo);
  },
  created() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s4.cnzz.com/z_stat.php?id=1281102051&web_id=1281102051";
    // document.body.appendChild(s);
  },
};
</script> 


<style scoped lang="less">
#app {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
}
.ocrIdentifyTop {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.basisInfoView {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 10px 16px;
}
.title {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  box-sizing: border-box;
}
.text {
  font-size: 10px;
  font-weight: 400;
  color: #fe4000;
}
/* 基础信息 */
.basisInfoViewLi {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.basisInfoViewLiText {
  width: 130px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
.basisInfoViewLi > input {
  border: none;
  flex: 1;
  font-size: 14px;
  height: 24px;
  font-weight: bold;
  color: #333333;
  // margin-left: 10px;
}
input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
/* 特殊 */
.specialView {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px 0;
}
.specialViewLi {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 13px;
}
.specialViewLi > .text {
  width: 70px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.van-radio-group {
  margin-left: 50px;
}
.specialViewLi .van-radio {
  flex: 1;
}
/deep/.specialViewLi .van-radio__label {
  font-size: 14px !important;
  width: 70px;
  font-weight: 400;
  color: #333333;
}
.carInfoViewLi {
  width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.carInfoViewLiText {
  width: 114px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.carInfoViewLi > input {
  border: none;
  /* width: 186px; */
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
.buttonView {
  width: 100%;
  height: 44px;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
}
.cancelBut {
  width: 48%;
  height: 44px;
  background: #ffffff;
  border-radius: 5px;
  border: 2px solid #1271ff;
  font-size: 16px;
  font-weight: bold;
  color: #1271ff;
}
.confirmBut {
  width: 48%;
  height: 44px;
  background: #1271ff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  border: none;
}
.colorClass {
  color: #cccccc !important;
}
.othTitle {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #f3f3f3;
}
.o_ul {
  min-height: 150px;
  max-height: 300px;
  overflow: scroll;
  box-sizing: border-box;
  padding: 15px;
}
.o_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.o_div > div:nth-of-type(1) {
  width: 100px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.o_div > div:nth-of-type(2) {
  flex: 1;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.o_dis {
  padding: 20px 0;
}
.oDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oDiv > div:nth-of-type(1) {
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
}
.oDiv > div:nth-of-type(2) {
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1271ff;
}
.image {
  margin-top: 20px;
  width: 163px;
  height: 108px;
  background: #f0f6ff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.remoteImage {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  z-index: 99999;
}
.texts {
  width: 163px;
  line-height: 20px;
  font-size: 12px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.confrims {
  margin: 20px auto 0;
  width: 270px;
  height: 44px;
  background: #1271ff;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
  margin-bottom: 30px;
}
.icon_image {
  width: 16px;
  height: 16px;
}
</style>
